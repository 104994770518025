var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        lazy: "",
        "no-fade": "",
        "no-enforce-focus": "",
        "hide-footer": "",
        size: "md",
        title: _vm.$t("payments.titles.creditBalance"),
      },
      on: { hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _c(
        "b-container",
        { staticClass: "p-0" },
        [
          _c(
            "b-form-row",
            [
              _c("mask-input", {
                attrs: {
                  "mask-label": "Valor Pago",
                  prepend: _vm.$t("payments.labels.currency"),
                  "parent-class": "col-md-5 col-sm-12",
                  "data-test": "modal-transaction__input-amount-paid",
                  disabled: "",
                  mask: ["####,##", "###,##", "##,##", "#,##"],
                },
                model: {
                  value: _vm.modalData.amount_paid,
                  callback: function ($$v) {
                    _vm.$set(_vm.modalData, "amount_paid", $$v)
                  },
                  expression: "modalData.amount_paid",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c("mask-input", {
                attrs: {
                  "mask-label": "Valor disponível para creditar",
                  prepend: _vm.$t("payments.labels.currency"),
                  "parent-class": "col-md-5 col-sm-12",
                  "data-test": "modal-transaction__input-amount-paid",
                  disabled: "",
                  mask: ["####,##", "###,##", "##,##", "#,##"],
                },
                model: {
                  value: _vm.modalData.total_available,
                  callback: function ($$v) {
                    _vm.$set(_vm.modalData, "total_available", $$v)
                  },
                  expression: "modalData.total_available",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c("mask-input", {
                attrs: {
                  "mask-label": "Valor convertido para Créditos",
                  prepend: _vm.$t("payments.labels.currency"),
                  "parent-class": "col-md-5 col-sm-12",
                  mask: ["####,##", "###,##", "##,##", "#,##"],
                  "field-state":
                    _vm.amountConvertError && _vm.biggerAmountError,
                },
                model: {
                  value: _vm.amount_convert,
                  callback: function ($$v) {
                    _vm.amount_convert = $$v
                  },
                  expression: "amount_convert",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Motivo de Creditação" } },
            [
              _c("b-form-radio-group", {
                attrs: {
                  id: "radio-slots",
                  options: _vm.creditReasonOptions,
                  "field-state": _vm.creditReasonError,
                  stacked: "",
                  name: "radio-options-slots",
                },
                model: {
                  value: _vm.creditReason,
                  callback: function ($$v) {
                    _vm.creditReason = $$v
                  },
                  expression: "creditReason",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-alert",
                {
                  staticClass: "col-12 text-center",
                  attrs: { variant: "danger", show: !_vm.amountConvertError },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("payments.labels.amountConvertError")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-alert",
                {
                  staticClass: "col-12 text-center",
                  attrs: { variant: "danger", show: !_vm.biggerAmountError },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("payments.labels.biggerAmountErrror")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-alert",
                {
                  staticClass: "col-12 text-center",
                  attrs: { variant: "danger", show: !_vm.creditReasonError },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("payments.labels.creditReasonError")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-12 col-sm-12",
                  attrs: {
                    label: _vm.$t("payments.labels.paymentComments"),
                    "label-for": "transaction-internal-comments",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: { type: "text", rows: 1, "max-rows": 4 },
                    model: {
                      value: _vm.useCreditInternalComments,
                      callback: function ($$v) {
                        _vm.useCreditInternalComments = $$v
                      },
                      expression: "useCreditInternalComments",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "float-right pt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: { "data-test": "modal-transaction__btn-save" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModalCreditBalance")
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: {
                    variant: "primary",
                    disabled: _vm.loading,
                    "data-test": "modal-transaction__btn-save",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDebitCredits()
                    },
                  },
                },
                [_vm._v(" Confirmar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }