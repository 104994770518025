<template>
  <b-modal
    v-model="cpHandlerModal"
    lazy
    no-fade
    no-enforce-focus
    hide-footer=""
    size="md"
    :title="$t('payments.titles.creditBalance')"
    @hidden="handleClose"
  >

    <b-container class="p-0">
      <b-form-row>
        <mask-input
          v-model.lazy="modalData.amount_paid"
          mask-label="Valor Pago"
          :prepend="$t('payments.labels.currency')"
          parent-class="col-md-5 col-sm-12"
          data-test="modal-transaction__input-amount-paid"
          disabled :mask="['####,##', '###,##', '##,##', '#,##']"
        />
      </b-form-row>
      <b-form-row>

       <mask-input
          v-model.lazy="modalData.total_available"
          mask-label="Valor disponível para creditar"
          :prepend="$t('payments.labels.currency')"
          parent-class="col-md-5 col-sm-12"
          data-test="modal-transaction__input-amount-paid"
          disabled :mask="['####,##', '###,##', '##,##', '#,##']"
        />
      </b-form-row>


      <b-form-row>
        <mask-input
          v-model="amount_convert"
          mask-label="Valor convertido para Créditos"
          :prepend="$t('payments.labels.currency')"
          parent-class="col-md-5 col-sm-12"
          :mask="['####,##', '###,##', '##,##', '#,##']"
          :field-state="(amountConvertError && biggerAmountError)"
        />
      </b-form-row>

    <b-form-group
      label="Motivo de Creditação"
    >
      <b-form-radio-group
        id="radio-slots"
        v-model="creditReason"
        :options="creditReasonOptions"
        :field-state="creditReasonError"
        stacked
        name="radio-options-slots"
      />
    </b-form-group>
      <b-form-row>
         <b-alert
          variant="danger"
          :show="!amountConvertError"
          class="col-12 text-center" >
           {{ $t('payments.labels.amountConvertError') }}
          </b-alert>
      </b-form-row>
      <b-form-row>
         <b-alert
          variant="danger"
          :show="!biggerAmountError"
          class="col-12 text-center" >
           {{ $t('payments.labels.biggerAmountErrror') }}
          </b-alert>
      </b-form-row>
      <b-form-row>
         <b-alert
          variant="danger"
          :show="!creditReasonError"
          class="col-12 text-center" >
           {{ $t('payments.labels.creditReasonError') }}
          </b-alert>
      </b-form-row>
      <b-form-row>
        <b-form-group
          :label="$t('payments.labels.paymentComments')"
          label-for="transaction-internal-comments"
          class="col-md-12 col-sm-12"
          >

            <b-form-textarea
              type="text"
              v-model="useCreditInternalComments"
              :rows="1"
              :max-rows="4"
            />
        </b-form-group>
      </b-form-row>
      <div class="float-right pt-2">
        <b-button
          class="ml-2"
          data-test="modal-transaction__btn-save"
          @click="$emit('closeModalCreditBalance')"
        >
          Cancelar
        </b-button>

        <b-button
          variant="primary"
          class="ml-2"
          :disabled="loading"
          data-test="modal-transaction__btn-save"
          @click="confirmDebitCredits()"
        >
          Confirmar
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import CREDIT_BALANCE from '@graphql/transaction-operations/mutations/create.gql';
import GET_CREDIT_REASON from '@graphql/transaction-operations/queries/get-credit-reason.gql';
import UPDATE_AMOUNT_PAID from '@graphql/transaction/mutations/updateAmountPaid.gql';
import { makeMutation } from "@graphql/middleware";
import { mapGetters } from 'vuex'
import { i18next } from '@translate/i18n';

export default {
  name: 'ModalCreditBalance',
  data () {
    return {
      useCreditInternalComments: '',
      amount: '',  
      amount_convert: null,
      creditReason: '',  
      creditReasonError: true,
      amountConvertError: true,
      biggerAmountError: true,
      validAmountConvertToCredit: true,
      creditReasonOptions: [],
      loading: false
    }
  },
  props: {
    handlerModal: {
      type: Boolean,
    },
    modalData: {
      type: Object
    }
  },
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  computed: {
    ...mapGetters('user', ['attributes']),
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      }
    },
  },
  methods: {

   async getCreditReason() {
     try {
        const response = await this.$apollo.query({
          query: GET_CREDIT_REASON,
        });
        response.data.getCreditReasons.items
        .map((i) => this.creditReasonOptions.push({
                        text: i18next.t(`transaction.creditReason.${i}`), 
                        value: i
                        }));
      } catch (err) {
        this.$log.logError(err);
      }
    },

    confirmDebitCredits () {
      this.loading = true;
      this.biggerAmountError = true;
      this.amountConvertError = true;
      this.creditReasonError = true;

      if (!this.creditReason) {
        this.creditReasonError = false;
      }

      if (!this.amount_convert) {
        this.amountConvertError = false;
      }

      if(parseInt(this.amount_convert) > parseInt(this.modalData.total_available)) {
        this.biggerAmountError = false;
      }

      if (!this.biggerAmountError || !this.amountConvertError || !this.creditReasonError) {
        this.loading = false;
        return false;
      }

      const amountPaidFormatted = this.amount_convert.replace(/[.,]/g, '');

      makeMutation(
        CREDIT_BALANCE,
        {
          transaction: this.modalData.id,
          invoice: this.modalData.invoice,
          driver: this.modalData.driver,
          credit_reason: this.creditReason,
          amount_operation: parseInt(amountPaidFormatted),
          requested_by: this.attributes.email,
          internal_comments: this.useCreditInternalComments,
          kind: 'CREDIT_APPLYED',
        }
      )
      .then(() => {
        this.$emit('successCreditBalance');
        this.$emit('closeModalCreditBalance');
      })
      .catch(() => {
        this.$emit('errorCreditBalance');
      })
      .finally(() => {
        this.loading = false;
      });
    },
    handleClose() {
      this.$emit('change', false);
    }
  },
  mounted() {
    this.getCreditReason();
    if(this.modalData.status === 'PAID' && this.modalData.amount_paid === 0) {
      makeMutation(
        UPDATE_AMOUNT_PAID,
        {
          transactionId: this.modalData.id
        }
      )
      .then((response) => {
        const data = response.data.updateAmountPaid
        this.modalData.amount_paid = data.amount_paid
        this.$emit('updateAmountPaid', { id: this.modalData.id, amount_paid: this.modalData.amount_paid })
      })
    }
  }
}
</script>
